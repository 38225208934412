import "styles/pages/page-404.scss";

import React, { useState, useEffect } from "react";
import { Link, navigate, useStaticQuery, graphql } from "gatsby";

import Layout from "components/Layout";
import Seo from "components/Seo";

const NotFoundPage = ({ location }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const reffer_page = location?.pathname?.split("/")[1];

    if (reffer_page === "zespol") {
      return navigate("/zespol/");
    }

    setLoading(false);
  }, [location.pathname]);

  const pageData = useStaticQuery(
    graphql`
      query errorPage {
        allWpPage(filter: { title: { eq: "404" } }) {
          nodes {
            seo {
              metaDesc
              metaKeywords
              title
            }
            slug
            title
            uri
            pageError {
              bigSign
              errorBtn
              errorLink
              errorText
            }
          }
        }
      }
    `
  );

  if (loading) return null;

  return (
    <Layout>
      <Seo title="404 - strony nie znaleziono" />
      <section className="page-error">
        <div className="container">
          <h1 className="page-error__number">
            {pageData.allWpPage.nodes[0].pageError.bigSign}
          </h1>
          <div
            className="page-error__title"
            dangerouslySetInnerHTML={{
              __html: pageData.allWpPage.nodes[0].pageError.errorText,
            }}
          />
          <Link
            to={pageData.allWpPage.nodes[0].pageError.errorLink}
            className="button button--green"
          >
            {pageData.allWpPage.nodes[0].pageError.errorBtn}
          </Link>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
